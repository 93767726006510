import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateProps {
  InsightsData: any;
  SalesTableList: any;
  VendorLocationsList: any;
  VendorDetailsList: any;
  InsightsFilteredData: any;
  SalesTableListPag: any;
  totalRecords: any;
  pagination: any;
}

const initialState: initialStateProps = {
  InsightsData: [],
  SalesTableList: [],
  VendorLocationsList: [],
  VendorDetailsList: [],
  InsightsFilteredData: [],
  SalesTableListPag: 0,
  totalRecords: 0,
  pagination: 0,
};

const insightsListDetailsReducer = createSlice({
  name: "insightsListDetailsReducer",
  initialState,
  reducers: {
    setInsightsList: (state, action) => {
      state.InsightsData = action.payload;
    },
    setSalesTableListPag: (state, action) => {
      state.SalesTableListPag = action.payload;
    },
    setSalesTableData: (state, action) => {
      state.SalesTableList = action.payload;
    },
    setTotalRecords: (state, action: PayloadAction<any>) => {
      state.totalRecords = action?.payload;
    },
    setPagination: (state, action: PayloadAction<number>) => {
      state.pagination = action?.payload;
    },
    setVenodrLocations: (state, action) => {
      state.VendorLocationsList = action.payload;
    },
    setVendorDetails: (state, action) => {
      state.VendorDetailsList = action.payload;
    },
    setInsightsFliteredData: (state, action) => {
      state.InsightsFilteredData = action.payload;
    },
  },
});

export const {
  setInsightsList,
  setSalesTableData,
  setVenodrLocations,
  setVendorDetails,
  setInsightsFliteredData,
  setSalesTableListPag,
  setTotalRecords,
  setPagination
} = insightsListDetailsReducer.actions;
export default insightsListDetailsReducer.reducer;
